/* eslint-disable no-self-compare */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable  */
import React, { useEffect } from "react";
import {
  AudioInputControl,
  AudioOutputControl,
  ControlBar,
  ControlBarButton,
  ContentShareControl,
  Phone,
  useMeetingManager,
  MeetingStatus,
  useMeetingStatus,
  VideoInputControl,
  VideoTileGrid,
  useLocalVideo,
} from "amazon-chime-sdk-component-library-react";
import { DefaultDeviceController, Device, EventAttributes, EventName } from "amazon-chime-sdk-js";
import Swal from "sweetalert2";

import type { ChimeMeetingProps } from "../types";

const videoInputSelectionToDevice = (deviceId: string): Device => {
  if (deviceId === 'blue') {
    return DefaultDeviceController.synthesizeVideoDevice('blue');
  }
  if (deviceId === 'smpte') {
    return DefaultDeviceController.synthesizeVideoDevice('smpte');
  }
  if (deviceId === 'none') {
    return null;
  }
  return deviceId;
};

function ChimeMeeting(props: ChimeMeetingProps) {
  const { Attendee, Meeting } = props;

  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();
  const { isVideoEnabled, setIsVideoEnabled, toggleVideo } = useLocalVideo();
   
  const toggleOnVideo = async (): Promise<void> => {
    await meetingManager.audioVideo?.chooseVideoInputDevice(
      meetingManager.selectedVideoInputTransformDevice
    );
    meetingManager.audioVideo?.startLocalVideoTile();
    setIsVideoEnabled(true);
  };

  const onVideoInputDeviceIsSelected = (selectedVideoInputDevice: string | null) => {
    console.debug("ChimeMeeting.onVideoInputDeviceIsSelected selectedVideoInputDevice", selectedVideoInputDevice);
    console.debug("ChimeMeeting.onVideoInputDeviceIsSelected isVideoEnabled", isVideoEnabled);
    if (selectedVideoInputDevice) {
      console.debug("ChimeMeeting.onVideoInputDeviceIsSelected selectedVideoInputDevice is not null");
    } else if (selectedVideoInputDevice == null) {
      console.debug("ChimeMeeting.onVideoInputDeviceIsSelected selectedVideoInputDevice is null");
    }
  };

  const onEventDidReceive = async (name: EventName, attributes: EventAttributes) => {
    console.debug("ChimeMeeting.onEventDidReceive name", name);
    console.debug("ChimeMeeting.onEventDidReceive attributes", attributes);
    const { attendeeId } = attributes;
    // NOTE: start video if previously enabled
    if (!isVideoEnabled && name == "attendeePresenceReceived") {
      if (attendeeId == Attendee.AttendeeId) {
        const enableVideoOnLaunch = true; // localStorage.getItem("enableVideoOnLaunch");
        console.debug("ChimeMeeting.onEventDidReceive enableVideoOnLaunch 1", enableVideoOnLaunch);
        if (enableVideoOnLaunch) {
          console.debug(
            "ChimeMeeting.onEventDidReceive meetingManager.selectedVideoInputDevice 1",
            meetingManager.selectedVideoInputDevice,
          );
          if (meetingManager.selectedVideoInputDevice) {
            meetingManager.selectVideoInputDevice(
              videoInputSelectionToDevice(meetingManager.selectedVideoInputDevice)
            ).then(
              async () => {
                console.debug(
                  "meetingManager.selectVideoInputDevice 1 successful"
                );
                console.debug(
                    "calling toggleOnVideo 1"
                );
                toggleOnVideo();
              }
            )
          } else {
            console.debug("ChimeMeeting.onEventDidReceive input device was not selected");
            console.debug("ChimeMeeting.onEventDidReceive calling meetingManager.listAndSelectDevices()");
            await meetingManager.listAndSelectDevices().then(
              async () => {
                console.debug(
                  "ChimeMeeting.onEventDidReceive meetingManager.selectedVideoInputDevice 1",
                  meetingManager.selectedVideoInputDevice,
                );
                if (meetingManager.selectedVideoInputDevice) {
                  meetingManager.selectVideoInputDevice(videoInputSelectionToDevice(
                    meetingManager.selectedVideoInputDevice)
                  ).then(
                    async () => {
                      console.debug(
                        "meetingManager.selectVideoInputDevice 2 successful"
                      );
                      console.debug(
                        "calling toggleOnVideo 2"
                      );
                      toggleOnVideo();
                    }
                  )
                } else {
                  console.debug("ChimeMeeting.onEventDidReceive input device was still not selected");
                }
              }
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    meetingManager.subscribeToSelectedVideoInputDevice(
      onVideoInputDeviceIsSelected,
    );

    return (): void => {
      meetingManager.unsubscribeFromSelectedVideoInputDevice(
        onVideoInputDeviceIsSelected,
      );
    };
  }, []);

  useEffect(() => {
    meetingManager.subscribeToEventDidReceive(
      onEventDidReceive,
    );

    return (): void => {
      meetingManager.unsubscribeFromEventDidReceive(
        onEventDidReceive,
      );
    };
  }, []);

  /* useEffect(() => {
    const enableVideoOnLaunch = localStorage.getItem("enableVideoOnLaunch");
    console.debug("ChimeMeeting.useEffect enableVideoOnLaunch", enableVideoOnLaunch);
    if (isVideoEnabled && !enableVideoOnLaunch) {
      console.debug("ChimeMeeting.useEffect setting enableVideoOnLaunch to true", isVideoEnabled);
      localStorage.setItem("enableVideoOnLaunch", "true");
    }
  }, [isVideoEnabled]); */

  useEffect(() => {
    try {
      console.info("calling join meeting...");
      meetingManager
        .join({
          meetingInfo: Meeting,
          attendeeInfo: Attendee,
        })
        .then(() => {
          console.info("meeting join complete.");
          console.info("calling start meeting...");
          // At this point you can let users setup their devices, or start the session immediately
          meetingManager
            .start()
            .then(() => {
              console.info("meeting start complete.");
            })
            .catch((err) => {
              Swal.fire({
                title: "Error",
                text: `${err.message}`,
                icon: "error",
              });
              console.info("meeting start error occurred.", err);
            });
        })
        .catch((err) => {
          Swal.fire({
            title: "Error",
            text: `${err.message}`,
            icon: "error",
          });
          console.info("meeting join error occurred.", err);
        });
    } catch (err: any) {
      Swal.fire({
        title: "Error",
        text: `${err.message}`,
        icon: "error",
      });
      console.error(err);
    }
  }, [Attendee, Meeting]);

  const clickedEndMeeting = async () => {
    const { meetingId } = meetingManager;
    if (meetingId) {
      await meetingManager.leave();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        backgroundColor: "#000000",
      }}
    >
      <VideoTileGrid />
      {meetingStatus === MeetingStatus.Succeeded ? (
        <ControlBar layout="undocked-horizontal" showLabels>
          <AudioInputControl />
          <VideoInputControl />
          <AudioOutputControl />
          <ContentShareControl />
          <ControlBarButton
            icon={<Phone />}
            onClick={clickedEndMeeting}
            label="End"
          />
        </ControlBar>
      ) : (
        <div />
      )}
    </div>
  );
}

export default ChimeMeeting;
