/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import DetectRTC from "detectrtc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";


function WebRTCDetector() {
  const [hasWebcam, setHasWebcam] = useState(false);
  const [hasMicrophone, setHasMicrophone] = useState(false);
  const [isScreenCapturingSupported, setIsScreenCapturingSupported] = useState(false);
  const [isWebRTCSupported, setIsWebRTCSupported] = useState(false);
  const [isWebsiteHasMicrophonePermissions, setIsWebsiteHasMicrophonePermissions] = useState(false);
  const [isWebsiteHasWebcamPermissions, setIsWebsiteHasWebcamPermissions] = useState(false);
  const [isWebSocketsSupported, setIsWebSocketsSupported] = useState(false);

  const detectRTCCallback = (stream = null) => {
    setHasWebcam(DetectRTC.hasWebcam)          // (has webcam device!)
    setHasMicrophone(DetectRTC.hasMicrophone); // (has microphone device!)
    setIsWebRTCSupported(DetectRTC.isWebRTCSupported);
    setIsWebSocketsSupported(DetectRTC.isWebSocketsSupported);
    setIsScreenCapturingSupported(DetectRTC.isScreenCapturingSupported);               // Chrome, Firefox, Opera, Edge and Android
    setIsWebsiteHasWebcamPermissions(DetectRTC.isWebsiteHasWebcamPermissions);         // getUserMedia allowed for HTTPs domain in Chrome?
    setIsWebsiteHasMicrophonePermissions(DetectRTC.isWebsiteHasMicrophonePermissions); // getUserMedia allowed for HTTPs domain in Chrome?
    if (stream) {
      // @ts-ignore
      stream.getTracks().forEach(
        // @ts-ignore
        (track) => {
          track.stop();
        }
      );
    }
  };

  async function runWebRTCCheck() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(
        {
          audio: true,
          video: true
        }
      );
      DetectRTC.load(
        () => {
          // @ts-ignore
          detectRTCCallback(stream);
        }
      );
    } catch (err) {
      console.error(err);
      DetectRTC.load(
        () => {
          detectRTCCallback();
        }
      );
    }
  };

  useEffect(() => {
    runWebRTCCheck();
  }, []);

  return (
    <table style={{backgroundColor: "#ffffff"}}>
      <tbody>
        <tr>
          <td>WebRTC support</td>
          <td>
            {isWebRTCSupported ?
              <FontAwesomeIcon icon={faCheckCircle} />
              :
              <FontAwesomeIcon icon={faXmarkCircle} />
            }
          </td>
        </tr>
        <tr>
          <td>Microphone detection</td>
          <td>
            {hasMicrophone ?
              <FontAwesomeIcon icon={faCheckCircle} />
              :
              <FontAwesomeIcon icon={faXmarkCircle} />
            }
          </td>
        </tr>
        <tr>
          <td>Microphone permissions</td>
          <td>
            {isWebsiteHasMicrophonePermissions ?
              <FontAwesomeIcon icon={faCheckCircle} />
              :
              <FontAwesomeIcon icon={faXmarkCircle} />
            }
          </td>
        </tr>
        <tr>
          <td>Webcam detection</td>
          <td>
            {hasWebcam ?
              <FontAwesomeIcon icon={faCheckCircle} />
              :
              <FontAwesomeIcon icon={faXmarkCircle} />
            }
          </td>
        </tr>
        <tr>
          <td>Webcam permissions</td>
          <td>
            {isWebsiteHasWebcamPermissions ?
              <FontAwesomeIcon icon={faCheckCircle} />
              :
              <FontAwesomeIcon icon={faXmarkCircle} />
            }
          </td>
        </tr>
        <tr>
          <td>WebSockets support</td>
          <td>
            {isWebSocketsSupported ?
              <FontAwesomeIcon icon={faCheckCircle} />
              :
              <FontAwesomeIcon icon={faXmarkCircle} />
            }
          </td>
        </tr>
        <tr>
          <td>Screen capturing</td>
          <td>
            {isScreenCapturingSupported ?
              <FontAwesomeIcon icon={faCheckCircle} />
              :
              <FontAwesomeIcon icon={faXmarkCircle} />
            }
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default WebRTCDetector