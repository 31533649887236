import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ChimeMeetingContainer from "./components/ChimeMeetingContainer";
import WebRTCDetector from "./components/WebRTCDetector";

function Home() {
  return <h2>Home</h2>;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/insightgateway/:eventId/:meetingId/:participant"
          element={<ChimeMeetingContainer />}
        />
        <Route
          path="/check/"
          element={<WebRTCDetector />}
        />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
