/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ThemeProvider } from "styled-components";
import {
  MeetingProvider,
  darkTheme,
} from "amazon-chime-sdk-component-library-react";
import Swal from "sweetalert2";
import ChimeMeeting from "./ChimeMeeting";

function ChimeMeetingContainer() {
  const [status, setStatus] = useState("");
  const [meetingData, setMeetingData] = useState(null);
  const { eventId, meetingId, participant } = useParams();
  const sanitizedParticipant = `${participant}`.replace(/\/$/, "");
  const parameters = {
    meeting_id: `${meetingId}`,
    participant: `${sanitizedParticipant}`,
  };
  const urlParameters = new URLSearchParams(parameters);
  const url = `https://meetingapi.insightgateway.com/event/${eventId}/join_token?${urlParameters.toString()}`;

  useEffect(() => {
    fetch(url, {
      cache: "no-cache",
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log("responseData", responseData);
        const { detail, error } = responseData;
        if (detail) {
          Swal.fire({
            title: "Error",
            text: `${detail}`,
            icon: "error",
          });
          console.error("an error occurred", detail);
          setStatus("error");
        } else if (error) {
          Swal.fire({
            title: "Error",
            text: `${error.message}`,
            icon: "error",
          });
          console.error("an error occurred", error);
          setStatus("error");
        } else {
          setMeetingData(responseData);
          setStatus("success");
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          text: `${err.message}`,
          icon: "error",
        });
        console.error(err);
        setStatus("error");
      });
  }, [eventId, meetingId, participant]);

  console.log("ChimeMeetingContainer called with", useParams());

  return (
    <ThemeProvider theme={darkTheme}>
      <MeetingProvider>
        {status === "success" && meetingData && (
          <ChimeMeeting {...meetingData} />
        )}
      </MeetingProvider>
    </ThemeProvider>
  );
}

export default ChimeMeetingContainer;
